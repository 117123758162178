<template>
  <table :id="Tableid" class="table table-bordered table-sm" v-if="headers">
    <thead class="w3-light-grey">
      <tr>
        <th
          nowrap
          v-for="header in headers"
          :key="header.name"
          class="size11 ellipsis"
          :class="HeaderClass(header.value)"
          :title="header.text"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in rows" :key="row.id" :class="colorizeRow(headers, row)">
        <td
          v-for="header in headers"
          :key="header.value + '-' + row.id"
          class="size13"
          :class="TdClass(header.value, row)"
          :nowrap="TdNoWrap(header.value)"
          :data-sort="DataSort(header.value, row)"
        >
          <span v-html="cellData(header.value, row)"></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import $ from "jquery";
import "datatables.net/js/jquery.dataTables.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4.js";
import {
  formatCurrency,
  formattedDate,
  dbDateFormat,
  formatNumbers,
} from "@/helpers/utilities";
import { toRef, onMounted } from "vue";
export default {
  props: ["headers", "rows", "tableid", "source"],
  setup(props) {
    const Tableid = toRef(props, "tableid");
    const Source = toRef(props, "source");

    const HeaderClass = (header) => [
      header === "AmountInvested" ||
      header === "AmountPaid" ||
      header === "Amount" ||
      header === "MarginAtToday" ||
      header === "ExpectedValueAtMaturity" ||
      header === "ValueDate" ||
      header === "MaturityDate" ||
      header === "PaymentDate" ||
      header === "RedemptionDate" ||
      header === "UnitPrice" ||
      header === "OfferPrice" ||
      header === "UnitQuantity" ||
      header === "Bid"
        ? "w3-right-align"
        : "",
      header === "AmountInvested" ||
      header === "AmountPaid" ||
      header === "Amount" ||
      header === "MarginAtToday" ||
      header === "ExpectedValueAtMaturity" ||
      header === "UnitQuantity" ||
      header === "ValueDate" ||
      header === "MaturityDate" ||
      header === "PaymentDate" ||
      header === "RedemptionDate" ||
      header === "Tenor"
        ? "min-width12p"
        : "",
      header === "Description" ? "width38p" : "",
      header === "ExpectedValueAtMaturity" ||
      header === "ValueDate" ||
      header === "MaturityDate" ||
      header === "PaymentDate" ||
      header === "FundCode" ||
      header === "RedemptionDate"
        ? " max-width80"
        : "",
    ];
    const cellData = (header, row) => {
      if (
        header === "ValueDate" ||
        header === "MaturityDate" ||
        header === "PaymentDate" ||
        header === "RedemptionDate"
      )
        return formattedDate(row[header]);
      if (
        header === "AmountInvested" ||
        header === "AmountPaid" ||
        header === "Amount" ||
        header === "UnitPrice" ||
        header === "OfferPrice" ||
        header === "Bid" ||
        header === "MarginAtToday" ||
        header === "ExpectedValueAtMaturity" ||
        header === "Current_FundValue" ||
        header === "Margin"
      )
        return formatCurrency(row[header], "&#8358;");
      if (header === "UnitQuantity")
        return formatNumbers(4).format(row[header]);
      return row[header];
    };
    const TdClass = (header) => [
      header === "AmountInvested" ||
      header === "AmountPaid" ||
      header === "Amount" ||
      header === "MarginAtToday" ||
      header === "ExpectedValueAtMaturity" ||
      header === "ValueDate" ||
      header === "MaturityDate" ||
      header === "PaymentDate" ||
      header === "RedemptionDate" ||
      header === "UnitPrice" ||
      header === "OfferPrice" ||
      header === "UnitQuantity" ||
      header === "Bid"
        ? "w3-right-align"
        : "",
    ];
    const TdNoWrap = (header) =>
      header === "AmountInvested" ||
      header === "AmountPaid" ||
      header === "Amount" ||
      header === "MarginAtToday" ||
      header === "ExpectedValueAtMaturity" ||
      header === "ValueDate" ||
      header === "MaturityDate" ||
      header === "PaymentDate" ||
      header === "RedemptionDate" ||
      header === "UnitPrice" ||
      header === "OfferPrice" ||
      header === "UnitQuantity" ||
      header === "Bid";
    const DataSort = (header, row) => {
      if (
        header === "ValueDate" ||
        header === "MaturityDate" ||
        header === "PaymentDate" ||
        header === "RedemptionDate"
      ) {
        const d = row[header].substring(0, 10);
        if (d.includes("/")) return dbDateFormat(d);
        return row[header].substring(0, 10);
      }
      return "";
    };
    const colorizeRow = (headers, row) => {
      const classes = [];
      for (let h in headers) {
        let header = headers[h].value;
        if (header === "MaturityDate") {
          let md = new Date(row[header].substring(0, 10)).getTime();
          let today = new Date().getTime();
          if (
            Source.value !== "liquidation" &&
            Source.value !== "uninvested" &&
            md < today
          ) {
            classes.push("w3-text-red");
            return classes;
          }
        }
        if (
          Source.value !== "liquidation" &&
          Source.value !== "uninvested" &&
          (header === "AmountInvested" ||
            header === "AmountPaid" ||
            header === "Amount")
        ) {
          if (row[header] < 0) {
            classes.push("w3-text-red");
          }
        }
      }
      return classes;
    };

    onMounted(() => {
      $("#" + Tableid.value).DataTable({
        responsive: true,
        order: [[0, "desc"]],
        dom: '<<"w3-left"l><"clear"><t>ip>',
      });
    });
    return {
      formatCurrency,
      HeaderClass,
      TdClass,
      TdNoWrap,
      Tableid,
      cellData,
      DataSort,
      colorizeRow,
      Source,
    };
  },
};
</script>
<style scoped>
input[readonly] {
  background-color: #fff;
}
</style>