<template>
  <input
    name="StartDate"
    v-model="StartDate"
    type="text"
    class="StartDate form-control width100 d-inline-block border-radius-0"
    :placeholder="StartDate"
  />
  <span class="width20"> - </span>

  <input
    name="EndDate"
    v-model="EndDate"
    type="text"
    class="EndDate form-control width100 d-inline-block border-radius-0"
    :placeholder="EndDate"
  />
  <button
    type="button"
    @click.prevent="getTransaction"
    class="btn w3-red width60 border-radius-0"
  >
    Filter
  </button>
</template>
<script>
import $ from "jquery";
import "@dmuy/jquery-datepicker/duDatepicker.css";
import duDatepicker from "@dmuy/jquery-datepicker";
import { ref, onMounted } from "vue";
export default {
  emits: ["gettransactions"],
  setup(_, { emit }) {
    const Today = new Date();
    const yy = Today.getFullYear();
    const mm = Today.getMonth() + 1;
    const dd = Today.getDate();
    const MM = mm > 9 ? mm : "0" + mm;
    const DD = dd > 9 ? dd : "0" + dd;
    const endDate = DD + "/" + MM + "/" + yy;
    var oneYearAgo = new Date();
    oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
    const yyy = oneYearAgo.getFullYear();
    const mmm = oneYearAgo.getMonth() + 1;
    const ddd = oneYearAgo.getDate();
    const DDD = ddd > 9 ? ddd : "0" + ddd;
    const MMM = mmm > 9 ? mmm : "0" + mmm;
    const startDate = DDD + "/" + MMM + "/" + yyy;
    const StartDate = ref(startDate);
    const EndDate = ref(endDate);

    const getTransaction = () => {
      emit("gettransactions", {
        StartDate: StartDate.value,
        EndDate: EndDate.value,
      });
    };
    onMounted(() => {
      $(".StartDate")
        .duDatepicker({
          auto: true,
          maxDate: "today",
          format: "dd/mm/yyyy",
          clearBtn: true,
          theme: "red",
        })
        .on("datechanged", function (e) {
          StartDate.value = e.date;
          const nStartDate = StartDate.value.split("/");
          const newDate = new Date(
            nStartDate[2],
            nStartDate[1] - 1,
            nStartDate[0]
          );
          new Date(newDate.setDate(newDate.getDate() - 1));
          const yy = newDate.getFullYear();
          const mm = newDate.getMonth() + 1;
          const dd = newDate.getDate();
          const fDate = yy + "-" + mm + "-" + dd;
          $(".EndDate").duDatepicker("destroy");
          $(".EndDate").duDatepicker({
            auto: true,
            format: "dd/mm/yyyy",
            setValue: fDate,
            minDate: fDate,
            maxDate: "today",
            clearBtn: true,
            theme: "red",
          });
        });
      $(".EndDate")
        .duDatepicker({
          auto: false,
          setValue: "today",
          format: "dd/mm/yyyy",
          clearBtn: true,
          theme: "red",
        })
        .on("datechanged", function (e) {
          EndDate.value = e.date;
        });
    });

    return {
      StartDate,
      EndDate,
      duDatepicker,
      getTransaction,
    };
  },
};
</script>
<style scoped>
input[readonly] {
  background-color: #fff;
}
</style>